import React from "react"
import { graphql } from 'gatsby'
import PostsList from "../components/postsList/PostsList"

function TagList(props) {
  return <PostsList type="tags" props={props} />
}

export default TagList

export const pageQuery = graphql`
  query tagpage($tag: String!) {
    allContentfulRecipes(filter: { tags: { in: [$tag] } }) {
      edges {
        node {
          title
          slug
          contentful_id
          cooktime
          createdAt
          preptime
          publishDate(formatString: "MMMM Do, YYYY")
          servings
          spaceId
          updatedAt
          tags
          image {
            fluid {
              tracedSVG
              srcWebp
              srcSetWebp
              src
              sizes
              base64
              aspectRatio
              srcSet
            }
          }
        }
      }
    }
    allContentfulBlog(filter: { tags: { in: [$tag] } }) {
      edges {
        node {
          title
          slug
          contentful_id
          createdAt
          publishDate(formatString: "MMMM Do, YYYY")
          spaceId
          updatedAt
          tags
          image {
            fluid {
              tracedSVG
              srcWebp
              srcSetWebp
              src
              sizes
              base64
              aspectRatio
              srcSet
            }
          }
        }
      }
    }
  }
`
